
import { Context } from '@nuxt/types';
import { Component, getModule, mixins, Watch } from 'nuxt-property-decorator';
import Section from '~/models/section';
import { ScreenSlug } from '~/models/enums/screen-slug';
import { ScreenService } from '~/services/screen.service';
import DeviceMixin from '~/mixins/device.mixin';
import { SectionName } from '~/models/enums/section-name';
import ScreenModule from '~/store/ScreenModule';
import { API_URL } from '~/constants/api-url';
import { WidgetType } from '~/models/enums/widget-type';

@Component({
  name: 'dn-home-page',
  async asyncData({ $device, $axios, store }: Context) {
    try {
      const screenModule: ScreenModule = getModule(ScreenModule, store);

      const sections = await ScreenService.getScreenSections(
        $axios,
        ScreenSlug.HOMEPAGE,
      );

      //Main HP slider
      const mappedSections = sections.map((section) => ({
        ...section,
        contentUrl:
          section.widget === WidgetType.THREE_LEAFLET_WITH_BANNER_SLIDER
            ? API_URL.CMS.HERO_PRODUCT_BANNERS
            : section.contentUrl,
      }));

      //Video with products hardcoded until we get new cms
      mappedSections.splice(4, 0, {
        slug: 'video_with_products_slider',
        contentUrl: API_URL.CMS.VIDEO_WITH_PRODUCTS,
        widget: WidgetType.VIDEO_WITH_PRODUCTS_SLIDER,
        title: '',
        description: '',
        redirectUrl: '',
        content: undefined,
        contentId: '',
      });

      await screenModule.fetchScreen({
        axios: $axios,
        name: ScreenSlug.HOMEPAGE,
      });

      await screenModule.fetchSectionsContent({
        axios: $axios,
        sections: mappedSections,
        isMobile: $device.isMobile,
        name: SectionName.HOMEPAGE,
      });

      return {
        sections: mappedSections,
      };
    } catch (err) {
      throw err;
    }
  },
})
export default class HomePage extends mixins(DeviceMixin) {
  private sections!: Section[];
  private screenModule!: ScreenModule;

  private get sectionsContent(): Section[] {
    return this.$store.state.ScreenModule.sections[SectionName.HOMEPAGE];
  }

  private created() {
    this.screenModule = getModule(ScreenModule, this.$store);
  }

  @Watch('isMobile')
  private async fetchWidgetContent(current: boolean) {
    await this.screenModule.fetchSectionsContent({
      axios: this.$axios,
      sections: this.sections,
      isMobile: current,
      name: SectionName.HOMEPAGE,
    });
  }
}
